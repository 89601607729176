import Vue from 'vue'
import Router from 'vue-router'

import LandingScreen from './components/screen/LandingScreen.vue'
import LogInScreen from './components/screen/LogInScreen.vue'
import LogOutScreen from './components/screen/LogOutScreen.vue'
import AnalyticsScreen from './components/screen/AnalyticsScreen.vue'
import ProductsScreen from './components/screen/ProductsScreen.vue'
import SalesScreen from './components/screen/SalesScreen.vue'
import DeliveriesScreen from './components/screen/DeliveriesScreen.vue'

import App from './App.vue'

import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap/dist/js/bootstrap.min.js'

Vue.config.productionTip = false;
Vue.use(Router);

const router = new Router({
  routes: [
    { path: '/', component: LandingScreen },
    { path: '/login', component: LogInScreen },
    { path: '/logout', component: LogOutScreen },
    { path: '/analytics', component: AnalyticsScreen },
    { path: '/products', component: ProductsScreen },
    { path: '/sales', component: SalesScreen },
    { path: '/deliveries', component: DeliveriesScreen },
  ],
  mode: 'history',
});

new Vue({
  render: h => h(App),
  router,
}).$mount('#app');
