<template>
  <div>        
      <main>
        <section class="main">
          <div>
            <a href="https://apps.apple.com/us/app/id1471516667">
              <img src="@/img/logo.png" class="mb-4" alt="BookKeeper">
            </a>
            <h1 class="display-4">The BookKeeper</h1>
            <h4 class="mb-4">Manage your store in a pocket!</h4>
            <a class="btn btn-light btn-lg" :href="appStoreLink" role="button">Get in AppStore</a>
          </div>
        </section>
        <svg viewBox="0 0 500 54" style="display: block;">
          <path d="M0,25 C150,100 250,0 500,25 L500,00 L0,0 Z" style="stroke: none; fill: #ff3b30;"></path>
        </svg>
        <section class="features">
          <div class="container text-center">
            <h1 class="mb-5 mt-4 display-4">Features</h1>
            <div class="row">
              <div class="col-md-4">
                <img src="@/img/feature/feature-products.png" alt="Products" class="img-features">
                <div class="card-body">
                  <h5 class="card-title">Products</h5>
                  <p class="card-text">Create products, add photos, set prices, track amount. All products are always with you!</p>
                </div>
              </div>
              <div class="col-md-4">
                <img src="@/img/feature/feature-sales.png" alt="Sales" class="img-features">
                <div class="card-body">
                  <h5 class="card-title">Sales</h5>
                  <p class="card-text">Sell products with one tap, add description, see income. Selling products was never that easy!</p>
                </div>
              </div>
              <div class="col-md-4">
                <img src="@/img/feature/feature-deliveries.png" alt="Deliveries" class="img-features">
                <div class="card-body">
                  <h5 class="card-title">Deliveries</h5>
                  <p class="card-text">Attach delivery to sales, track parcels, scan tracking barcode. You'll never miss any delivery!</p>
                </div>
              </div>
            </div>
            <div class="m-4"></div>
            <div class="row">
              <div class="col-md-4">
                <img src="@/img/feature/feature-analytics.png" alt="Analytics" class="img-features">
                <div class="card-body">
                  <h5 class="card-title">Analytics</h5>
                  <p class="card-text">View analytics for sales and income. Check analytics anytime you want, no matter where you are!</p>
                </div>
              </div>
              <div class="col-md-4">
                <img src="@/img/feature/feature-sync.png" alt="Synchronization" class="img-features">
                <div class="card-body">
                  <h5 class="card-title">Synchronization</h5>
                  <p class="card-text">Synchronize all data between devices in realtime. Products, sales and deliveries are always with you!</p>
                </div>
              </div>
              <div class="col-md-4">
                <img src="@/img/feature/feature-personalization.png" alt="Personalization" class="img-features">
                <div class="card-body">
                  <h5 class="card-title">Personalization</h5>
                  <p class="card-text">Customize theme, currencies, product item view and much more. Make it yours!</p>
                </div>
              </div>
            </div>
            <div class="m-4">
              <a class="btn btn-main btn-lg" :href="appStoreLink" role="button">Get in AppStore</a>
            </div>
          </div>
        </section>
        <svg viewBox="0 0 500 50" style="display: block;">
          <path d="M 500,30 C 350,-45 250,55 0,30 v 50 h 500 z" style="fill: #ff3b30"></path>
        </svg>
        <section class="screenshots">
          <div class="container text-center">
            <h1 class="mb-5 display-4">Screenshots</h1>
            <div class="screenshot-container mb-5">
              <img src="@/img/screenshot/s3.jpg">
              <img src="@/img/screenshot/s4.jpg">
              <img src="@/img/screenshot/s5.jpg">
              <img src="@/img/screenshot/s6.jpg">
            </div>
            <a class="btn btn-light btn-lg" :href="appStoreLink" role="button">Get in AppStore</a>
          </div>
        </section>
        <svg viewBox="0 0 500 54" style="display: block;">
          <path d="M0,25 C150,100 250,0 500,25 L500,00 L0,0 Z" style="stroke: none; fill: #ff3b30;"></path>
        </svg>
        <section>
          <div class="container mt-5 mb-5">
            <h1 class="mb-4 display-4 text-center">The BookKeeper</h1>
            <p>If you are trading online and are often forced to send goods by mail, then this application is for you. Also, the application will suit an entrepreneur who has a retail outlet or store, and you sell goods and services.</p>
            <p>BookKeeper - mobile commerce was created to give you the opportunity to conduct business anytime, anywhere and placed in your smartphone. BookKeeper was created simple, so that you have an alternative to very complex applications. You can easily use BookKeeper, even if you have never worked with accounting applications before.</p>
            <h4>The BookKeeper makes it easy to:</h4>
            <ul>
              <li>Keep track of the balance of goods in real time</li>
              <li>Add a photo, name and description to the product</li>
              <li>Edit products and prices</li>
              <li>See the cost of a single product and all products</li>
              <li>Create sales of goods or services</li>
              <li>Add multiple items to one shipment or sale</li>
              <li>Track delivery status of mail items Nova Poshta, Ukrposhta, In Time, Russian Post and USPS</li>
              <li>Scan barcodes of mailings by the camera of your device</li>
              <li>Sync data between devices</li>
              <li>Work with data without internet when communication fails</li>
            </ul>
            <div class="m-4 text-center">
              <a class="btn btn-main btn-lg" :href="appStoreLink" role="button">Get in AppStore</a>
            </div>
          </div>
        </section>
        <section class="footer">
          <hr>
          <div class="container mt-5 mb-5">
            <div class="row">
              <div class="col">
                <h3>Contact</h3>
                <p><a :href="supportLink">Support</a></p>
              </div>
              <div class="col">
                <h3>Regulations</h3>
                <p><a :href="termsLink">Terms and Conditions</a></p>
                <p><a :href="privacyLink">Privacy Policy</a></p>
              </div>
              <div class="col">
                <h3>About</h3>
                <p><a :href="companyLink">Company</a></p>
              </div>
            </div>
          </div>
        </section>
      </main>
  </div>
</template>

<script>
export default {
  computed: {
    appStoreLink: function () {
        return 'https://apps.apple.com/us/app/id1471516667'
    },
    supportLink: function () {
        return 'https://crosscone.com/bookkeeper/support/'
    },
    termsLink: function () {
        return 'https://crosscone.com/bookkeeper/terms/en/'
    },
    privacyLink: function () {
        return 'https://crosscone.com/bookkeeper/privacy/en/'
    },
    companyLink: function () {
        return 'https://crosscone.com/'
    }
  }
}
</script>

<style lang="scss" scoped>
  $mainColor: #ff3b30;
  $mainColorAlt: #e8382e;

  body, html {
    color: #444444;
  }

  .btn-main {
    color: #fff;
    background-color: $mainColor;
    border-color: $mainColor;
  }

  .btn-main:hover {
    color: #fff;
    background-color: $mainColorAlt;
    border-color: $mainColorAlt;
  }

  section.main {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: $mainColor;
    color: #ffffff;

    img {
      width: 24vh;
      height: 24vh;
      border: 3px solid white;
      border-radius: 20%;
    }
  }

  section.features {
    img.img-features {
      width: 90px;
      height: auto;
      margin: auto;
    }
  }

  section.screenshots {
    background: $mainColor;
    color: #ffffff;

    div.screenshot-container {
      font-size: 0;
      padding: 4px;
      white-space: nowrap;
      overflow: hidden;
      background: #dddddd;
      border-radius: 8px;
    }

    img {
      width: calc(25% - 1%);
      margin: 0.5%;
      padding: 0;
      border-radius: 4px;
    }
  }

  section.footer {
    p {
      margin-bottom: 8px;
    }

    a {
      color: $mainColor;
      padding-left: 10px;
    }
  }
</style>