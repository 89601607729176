import axios from 'axios'
import uuid from 'uuid/v4'

export default {
  client: axios.create({
    baseURL: 'http://127.0.0.1/v1/',
    timeout: 1000,
    headers: {
      'Content-Type': 'application/json',
      'Grpc-Metadata-Token': uuid(),
      'Grpc-Metadata-Lang': 'en',
      'Grpc-Metadata-Client': 'web',
      'Grpc-Metadata-Version': '0.0.1',
    },
  }),

  auth(email: string, password: string): Promise<object> {
    return this.request('auth', { email, password, force: true })
  },

  deauth(): Promise<object> {
    return this.request('deauth', {})
  },

  createAccount(email: string, password: string): Promise<object> {
    return this.request('create-account', { email, password, repeatPassword: password })
  },

  request(method: string, data: object): Promise<object> {
    return this.client.post(method, data)
  },
}