<template>
  <AppLayout>
    Sales route
  </AppLayout>
</template>

<script>
import AppLayout from '../core/AppLayout'

export default {
  components: {
    AppLayout,
  }
}
</script>