<template>
    <div>
        Logging out..
    </div>
</template>

<script>
import authService from '../../service/auth'

export default {
    created: function () {
        authService.deauth().finally(() => {
            this.$router.push('/')
        })
    }
}
</script>