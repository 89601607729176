<template>
  <div class="container-login">
    <div class="login">
      <div class="text-center mb-4">
        <h1 class="h3 mb-3 font-weight-normal">BookKeeper Log In</h1>
      </div>

      <div v-if="showErrorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <div class="form-group">
        <input v-model="email" @input="onChange" type="email" class="form-control form-control-lg" placeholder="Email address" required autofocus>
      </div>

      <div class="form-group">
        <input v-model="password" @input="onChange" type="password" class="form-control form-control-lg" placeholder="Password" required>
      </div>

      <button @click="onLogIn" :disabled="logInDisabled" class="btn btn-lg btn-primary btn-block">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Log In
      </button>
    </div>
  </div>
</template>

<script>
import authService from '../../service/auth'

export default {
  data: function() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      canLogIn: false,
      loading: false,
    }
  },
  methods: {
    onChange: function () {
      this.canLogIn = this.email.length > 0 && this.password.length > 0
    },
    onLogIn: function () {
      this.loading = true
      authService.auth(this.email, this.password).then((resp) => {
        // @ts-ignore
        if (resp.data.account) {
          this.$router.push('/analytics')
          this.errorMessage = ''
        } else {
          this.errorMessage = 'Wrong email or password'
        }
      }).catch(() => {
        this.errorMessage = 'Can\'t log in, check our internet connection'
      }).finally(() => {
        this.loading = false
      })
    }
  },
  computed: {
    logInDisabled: function () {
      // @ts-ignore
      return !this.canLogIn || this.loading
    },
    showErrorMessage: function () {
      // @ts-ignore
      return this.errorMessage.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.container-login {
  display: flex;
  height: 100vh;
}

.login {
  width: 100%;
  max-width: 350px;
  margin: auto;
}
</style>