<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link class="nav-link" :class="{ active: isAnalytics }" to="/analytics">Analytics</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="{ active: isProducts }" to="/products">Products</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="{ active: isSales }" to="/sales">Sales</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="{ active: isDeliveries }" to="/deliveries">Deliveries</router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/logout">Log Out</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    isAnalytics: function () {
      return this.$route.path.startsWith('/analytics')
    },
    isProducts: function () {
      return this.$route.path.startsWith('/products')
    },
    isSales: function () {
      return this.$route.path.startsWith('/sales')
    },
    isDeliveries: function () {
      return this.$route.path.startsWith('/deliveries')
    }
  }
}
</script>